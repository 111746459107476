









import { Component, Vue }       from 'vue-property-decorator'
import EkkoHeaderNav            from './components/ekko.header-nav.vue'
import EkkoFooter               from './components/ekko.footer.vue'
import { EkkoLandingAnalytics } from '@/ekko-landing.analytics'

@Component({
  name: 'app',
  components: { EkkoHeaderNav, EkkoFooter },
})
export default class App extends Vue {
  created() {
    document.title = 'Nuked.TV';
  }
  mounted(){
    // done here because it triggers the initial page view
    // to be sure that we have the routing considered /for influencer as source)
    // we do it separately from EkkoLandingAnalytics.init()
    // once the initial routing is confirmed.
    EkkoLandingAnalytics.initGtagConfig()
  }
}
