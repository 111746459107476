import { RouteConfig }          from "vue-router"
import { influencers }          from '@/constants'
import { IEkkoInfluencer }      from '@/types'
import { EkkoLandingAnalytics } from '@/ekko-landing.analytics'
import EkkoHome                 from '@/components/ekko.home.vue'
import EkkoFooter               from '@/components/ekko.footer.vue'
import EkkoTermsOfService       from '@/components/ekko.terms-of-service.vue'
import EkkoFaq                  from '@/components/ekko.faq.vue'
import EkkoInfluencerCard       from '@/components/ekko.influencer-card.vue'
import EkkoDefaultHero          from '@/components/ekko.default-hero.vue'

const influencerRoutes: RouteConfig [] = influencers.map((i: IEkkoInfluencer) => {
  return {
    name: i.name,
    path: '/' + i.urlFragment,
    components: {
      content: EkkoHome,
      hero: EkkoInfluencerCard,
      footer: EkkoFooter
    },
    meta: { influencer: i.urlFragment },
    beforeEnter(to, from, next){
      const influencer = to.meta.influencer;
      if(influencer){
        EkkoLandingAnalytics.setCampaignSource(influencer)
      }
      next();
    }
  } as RouteConfig
})

export const routes: RouteConfig[] = [
  {
    name: 'home',
    path: '/',
    components: {
      content: EkkoHome,
      hero: EkkoDefaultHero,
      footer: EkkoFooter
    },
  },
  ...influencerRoutes,
  {
    name: 'terms-of-service',
    path: '/terms-of-service',
    components: {
      content: EkkoTermsOfService,
      footer: EkkoFooter
    },
  },
  {
    name: 'faq',
    path: '/faq',
    components: {
      content: EkkoFaq,
      footer: EkkoFooter
    },
  },
  {
    path: '*',
    redirect: '/',
  },
]
