






































































































































































import { Component, Vue } from 'vue-property-decorator'
import { gameLogos }      from '@/constants'
import EkkoButtonDownload from '@/components/ekko.button-download.vue'

@Component({
  name: 'ekko-home',
  components: { EkkoButtonDownload },
})
export default class EkkoHome extends Vue {
  protected logos
  protected isImageModalActive = false
  protected modalImage = ""

  beforeCreate() {
    this.logos = gameLogos
  }
}
