

















import { Component, Vue } from 'vue-property-decorator'
import EkkoButtonDownload from '@/components/ekko.button-download.vue'

@Component({
  name: 'ekko-default-hero',
  components: { EkkoButtonDownload  },
})
export default class EkkoDefaultHero extends Vue {
  
}
